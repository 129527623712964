import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, Button, ThemeProvider, createTheme, Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DashboardController, { Props } from "./DashboardController";
import { styles } from "./styles/styles";
import { OffIcon, OnImage, RightIcon, GreenRightIcon, BackIcon, HomeVisitIcon, TeleconsultationIcon, CallingIcon, HomeIcon, NextArrow, PrevArrow,DownArrow, SOSImage, NoData,UserDefaultIcon } from "./assets";
import { Bar } from "react-chartjs-2";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class DashboardView extends DashboardController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start

    async componentDidMount() {
        this.fetchAyahDashboardDetails()
        this.fetchAyahDashboardTotalBookingDetails()
    }

    months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    callCardDetails = (classes: any, id:number, userDetails?:any) => {
        if (!userDetails) return null;
        return (
            <Box className={classes.ayahDetailsContainer} component="div">
                <Box className={classes.ayahDetails} component="div">
                    <Typography variant="h6" className={classes.nameLabel}>
                        {userDetails.patient_name}
                    </Typography>
                    <Typography data-test-id={`showHealthDetailsDialog${id}`} variant="h6" className={classes.HealthDetailsLabel} onClick={() => this.fetchAyahDashboardPaitentHealthDetails(userDetails.id)}>Health Details</Typography>
                </Box>
                <Box className={classes.ayahDetails} component="div">
                    <Typography variant="h6" className={classes.ayahTimingLabel}>{userDetails.start_time} - {userDetails.end_time}</Typography>
                    <Typography variant="h6" className={classes.ayahWeekdayLabel}>{id == 5 ? this.formatDate(userDetails.start_date) : this.getWeekdayName(userDetails.start_date)}</Typography>
                </Box>
            </Box>
        )
    }

    headerBackButtonProvider = (classes: any, heading: string) => {
        return (
            <Box className={classes.backBtnContainer} component="div">
                <Box className={classes.backBtn} component="div">
                    <img
                        data-test-id="backToPreviousPage"
                        src={BackIcon}
                        className={classes.backIcon}
                        width={"50px"}
                        height={"50px"}
                        onClick={() => this.openDashboard(heading)}
                    />
                </Box>
                <Box className={classes.backBtnTitleContainer} component="div">
                    <Typography variant="h6" className={classes.pageTitle} style={{ textAlign: "center" }}>
                        {heading}
                    </Typography>
                </Box>
            </Box>
        )
    }

    transacationFiltersProvider = (classes: any,) => {
        return (
            <Box className={classes.transactionFiltersContainer} component="div">
                <Box className={classes.allFilterSection} component="div">
                    <Typography variant="h6" className={classes.allFilterlabel} >
                        All
                    </Typography>
                </Box>
                <Box className={classes.filterSection} component="div">
                    <Box className={classes.subFilterSection} component="div">
                        <img
                            data-test-id="earingIconImage"
                            src={HomeIcon}
                            width={"26px"}
                        />
                        <Typography variant="h6" className={classes.filterLabel}>
                            Home Visit
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.filterSection} component="div">
                    <Box className={classes.subFilterSection} component="div">
                        <img
                            data-test-id="earingIconImage"
                            src={CallingIcon}
                            width={"26px"}
                        />
                        <Typography variant="h6" className={classes.filterLabel}>
                            Teleconsultation
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    recentTransaction = (classes: any,index:number,totalBooking:any) => {
        const {
            formatedDateAndTime,
            FormatedDate,
          } = this.cardDateFormat(totalBooking.attributes.created_at)
        return (
            <>
                <Box className={classes.ayahImageContainer} component="div">
                    <img
                        data-test-id="ayahOval"
                        src={this.getPatientProfileImage(totalBooking.attributes.patient_profile_image)}
                        className={classes.ayahProfile}
                    />
                </Box>
                <Box className={classes.transitionInfoSection} component="div">
                    <Box className={classes.ayahDetails} component="div">
                        <Typography variant="h6" className={classes.nameLabel}>
                        {totalBooking.attributes.patient_name}
                        </Typography>
                        <Typography variant="h6" className={classes.transactionTiming}>{index === 1 ? formatedDateAndTime : FormatedDate}</Typography>
                    </Box>
                    <Typography variant="h6" className={classes.transactionAmt}>
                        ₹{totalBooking.attributes.amount}
                    </Typography>
                </Box>
            </>
        )
    }

    showCard = (classes:any,totalBooking:any,index:number) => {
        return (
            <Box className={classes.bookingWrapper} key={index}>
                <Box className={classes.ayahImageContainer}>
                    <img data-test-id="ayahOval0" src={this.getPatientProfileImage(totalBooking.attributes.patient_profile_image)} className={classes.ayahProfile} />
                </Box>
                <Box className={classes.bookingsInfoSection}>
                    <Box className={classes.ayahDetails}>
                        <Typography variant="h6" data-test-id="name" className={classes.nameLabel}>{totalBooking.attributes.patient_name}</Typography>
                        <Typography variant="h6" data-test-id={`showHealthDetails${index}`}className={classes.BookingHealthDetailsLabel} onClick={() => this.fetchAyahDashboardPaitentHealthDetails(totalBooking.id)}>Health Details</Typography>
                        <Typography variant="h6" data-test-id="time" className={classes.bookingTiming}>{totalBooking.attributes.start_time} - {totalBooking.attributes.end_time} | {this.formatDate(totalBooking.attributes.start_date)}</Typography>
                    </Box>
                    <Typography variant="h6" className={classes.homeVisitLabel}>Home Visit</Typography>
                </Box>
            </Box>
        )
    }

    noDataFound = (classes:any) => {
        return(
            <>
            {
                this.state.loading ? (
                <>
                    <Box className={classes.noData}>Loading...</Box>
                </>) : (
                <>
                    <Box className={classes.noDataDashboardContainer}>
                    <img
                        src={NoData}
                        width={60}
                        height={60}
                        className={classes.closeIcon}
                    />
                    <Box className={classes.noData}>No results found</Box>
                    </Box>
                </>)
            }
            </>
        )
    }

    ayahShift = (classes:any, index:number) => {
        const isShiftStarted = this.state.updateShiftStart[index] || false;
    
        return (
            <>
                <Box className={classes.ayahShiftAction}>
                    <Typography variant="h6" className={classes.shiftText} style={{ textAlign: "center" }}>Start</Typography>
                    {isShiftStarted ?
                        <span>
                            <img className={classes.icons} data-test-id={`updateIsShiftStartOn${index}`} src={OnImage} width={44} height={22} onClick={() => this.handelUpdateShift(index)} />
                        </span>
                        :
                        <span>
                            <img data-test-id={`updateIsShiftStartOff${index}`} src={OffIcon} width={44} height={22} className={classes.icons} onClick={() => this.handelUpdateShift(index)} />
                        </span>}
                </Box>
                <Box className={classes.ayahShiftAction} component="div">
                    <Typography variant="h6" className={classes.shiftText} style={{ textAlign: "center" }}>End</Typography>
                    {isShiftStarted ?
                        <span>
                            <img src={OffIcon} className={classes.icons} data-test-id={`updateIsShiftEndOff${index}`} width={44} height={22} onClick={() => this.handelUpdateShift(index)} />
                        </span>
                        :
                        <span>
                            <img data-test-id={`updateIsShiftEndOn${index}`} src={OnImage} className={classes.icons} width={44} height={22} onClick={() => this.handelUpdateShift(index)} />
                        </span>}
                </Box>
            </>
        );
    }

    handelBookingButton = (newBooking:any, classes:any, index:number) => {
        return (
            <>
                {this.state.bookingId === newBooking.data.attributes.id ? (
                    <Button
                        data-test-id="otpVerified"
                        variant="contained"
                        color="primary"
                        className={classes.acceptedBtn}
                        style={{ textTransform: "capitalize" }}
                    >
                        <span>
                            <img
                                src={RightIcon}
                                className={classes.icons}
                                width={18}
                                height={13}
                            />
                        </span>
                        <Typography
                            variant="h6"
                            className={classes.acceptedBtnText}
                            style={{ textAlign: "center" }}
                        >
                            Accepted
                        </Typography>
                    </Button>
                ) : (
                    <>
                        <Button
                            data-test-id={`Rejected${index}`}
                            variant="contained"
                            className={classes.declineBtn}
                            style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                this.updateBookingStatus(
                                    newBooking.data.id,
                                    "rejected",
                                    newBooking.data.attributes.id
                                );
                            }}
                        >
                            Decline
                        </Button>
                        <Button
                            data-test-id={`Accepted${index}`}
                            variant="contained"
                            className={classes.acceptBtn}
                            style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                this.updateBookingStatus(
                                    newBooking.data.id,
                                    "accepted",
                                    newBooking.data.attributes.id
                                );
                            }}
                        >
                            Accept
                        </Button>
                    </>
                )}
            </>
        )
    }

    renderBookingList(classes: any) {
        return (
            <>
            {this.state.allBooking?.length > 0 ? (
                    this.state.allBooking.map((bookingData: any, index: number) => (
                        <Box className={classes.upcomingBookingListContainer} component="div" key={index}>
                            <Box className={classes.bookingContainer} component="div">
                                <Box className={classes.ayahInfoContainer} component="div">
                                    <Box className={classes.ayahImageContainer} component="div">
                                        <img data-test-id="ayah" src={this.getPatientProfileImage(bookingData.attributes.patient_profile_image)} className={classes.ayahProfile} />
                                    </Box>
                                    <Box className={classes.ayahInfoSection} component="div">
                                        {this.callCardDetails(classes, 5, bookingData.attributes)}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))
                ) : (
                    this.noDataFound(classes)
                )
            }
           </>
        )
    }

    healthDetailsContainer = (classes:any) => {
        return (
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {this.state.healthDetails?.attributes?.diseases?.length > 0 ? (
                            this.state.healthDetails?.attributes.diseases.map((disease: any, index: number) => (
                                <Box key={index} className={classes.DialogContentSpace}>
                                    <Typography variant="body2" className={classes.questionField}>Disease Name</Typography>
                                    <Typography variant="body2" className={classes.valueField}>{disease.disease_name}</Typography>
                                </Box>
                            ))
                        ) : (
                            <Box className={classes.DialogContentSpace}>
                                <Typography variant="body2" className={classes.questionField}>Disease Name</Typography>
                                <Typography variant="body2" className={classes.valueField}>-</Typography>
                            </Box>
                        )}
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Height</Typography>
                            <Typography variant="body2" className={classes.valueField}>{this.state.healthDetails?.attributes?.patient_info.height} cm</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Medicine</Typography>
                            {this.state.healthDetails?.attributes?.medicines?.length > 0 ? (
                                this.state.healthDetails?.attributes.medicines.map((medicine: any, index: number) => (
                                    <Box key={index} className={classes.DialogContentSpace}>
                                        <Typography variant="body2" className={classes.valueField}>{index + 1}. {medicine.name}</Typography>
                                    </Box>))
                            ) : (
                                <Box className={classes.DialogContentSpace}>
                                    <Typography variant="body2" className={classes.valueField}>-</Typography>

                                </Box>
                            )}
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Previous Surgery</Typography>
                            <Typography variant="body2" className={classes.valueField}>{this.state.healthDetails?.attributes?.surgeries.length > 0 ? "Yes" : "No"}</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Smoke</Typography>
                            <Typography variant="body2" className={classes.valueField}>No</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Prescription</Typography>
                            <Typography variant="body2" className={classes.valueField}>Not available</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {this.state.healthDetails?.attributes?.diseases?.length > 0 ? (
                            this.state.healthDetails?.attributes.diseases.map((disease: any, index: number) => (
                                <Box key={index} className={classes.DialogContentSpace}>
                                    <Typography variant="body2" className={classes.questionField}>MM / YYYY</Typography>
                                    <Typography variant="body2" className={classes.valueField}>{this.getMonthNumber(disease.disease_months)} / {disease.disease_years}</Typography>
                                </Box>))
                        ) : (
                            <Box className={classes.DialogContentSpace}>
                                <Typography variant="body2" className={classes.questionField}>MM / YYYY</Typography>
                                <Typography variant="body2" className={classes.valueField}>-</Typography>
                            </Box>
                        )}
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Weight</Typography>
                            <Typography variant="body2" className={classes.valueField}>{this.state.healthDetails?.attributes?.patient_info.weight} Kg</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Allergies</Typography>
                            {this.state.healthDetails?.attributes?.allergies?.length > 0 ? (
                                this.state.healthDetails?.attributes.allergies.map((allergie: any, index: number) => (
                                    <Box key={index} className={classes.DialogContentSpace}>
                                        <Typography variant="body2" className={classes.valueField}>{index + 1}. {allergie.name}</Typography>

                                    </Box>))
                            ) : (
                                <Box className={classes.DialogContentSpace}>
                                    <Typography variant="body2" className={classes.valueField}>-</Typography>

                                </Box>
                            )}
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Previous Hospital</Typography>
                            <Typography variant="body2" className={classes.valueField}>{this.state.healthDetails?.attributes?.patient_info.previous_hospital ? "Yes" : "No"}</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Drink</Typography>
                            <Typography variant="body2" className={classes.valueField}>Yes</Typography>
                        </Box>
                        <Box className={classes.DialogContentSpace}>
                            <Typography variant="body2" className={classes.questionField}>Reports</Typography>
                            <Typography variant="body2" className={classes.valueField}>Not available</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        )
    }

    seeAllData = (dataFound:any) => {
        return {
            textAlign: "center" as "center",
            color: dataFound?.length > 0 ? "#3780E0" : "#808080",
            cursor: dataFound?.length > 0 ? "pointer" : "unset" as "unset" | "pointer",
        }
    }

    showHorizontalLine = (classes: any,index:number) => {
        return (<>
            {index < Math.min(this.state.totalBookingDetails.length, 10) - 1 && (
                <Box className={classes.horizontalLine}></Box>
            )}</>)
    } 

    recentTransactionSelection = (classes:any) => {
        return (<>
            <Box className={classes.monthFilterContainer} component="div">
                <Box className={classes.changeMonthBtn} component="div">
                    {this.state.selectedMonth > 0 && (
                        <img
                            data-test-id="backwardIcon"
                            src={PrevArrow}
                            className={classes.backIcon}
                            width={8}
                            height={13}
                            onClick={() => this.selectedPreviousMonth()}
                        />
                    )}
                </Box>
                <Typography variant="h6" className={classes.selectFilter}>
                    {this.months[this.state.selectedMonth]}
                </Typography>
                <Box className={classes.changeMonthBtn} component="div">
                    {this.state.selectedMonth < 11 && (
                        <img
                            data-test-id="forwardIcon"
                            src={NextArrow}
                            className={classes.backIcon}
                            height={13}
                            width={8}
                            onClick={() => this.selectedNextMonth()}
                        />
                    )}
                </Box>
            </Box>
            <Box className={classes.yearFilterContainer} component="div">
                <Box data-test-id="cars" className={classes.selectFilter}>
                    {this.state.selectedYear}
                </Box>
                {this.state.showYear && (
                    <Box
                        className={classes.yearBox}
                    >
                        {Array.from({ length: 41 }, (_, index) => 2010 + index).map((year,index) => (
                            <Box
                                key={year}
                                style={{
                                    padding: "5px 0",
                                    textAlign: "center",
                                    cursor: "pointer",
                                }}
                                data-test-id={`selectedYear${index}`}
                                onClick={() => this.selectedYear(year)}
                            >
                                {year}
                            </Box>
                        ))}
                    </Box>
                )}

                <Box className={classes.changeMonthBtn} component="div">
                    <img
                        data-test-id="selectYear"
                        src={DownArrow}
                        className={classes.dropDown}
                        width={13}
                        height={10}
                        onClick={() => { this.setState({ showYear: !this.state.showYear }) }}
                    />
                </Box>
            </Box></>)
    }

    getPatientProfileImage(patient_profile_image : string | undefined) {
        return patient_profile_image || UserDefaultIcon
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const options = {
            responsive: true,
            legend: {
                display: false,
            },
            type: "bar",
            scales:{
                xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        fontSize: 14,
                        fontFamily:"SF Pro Text Regular",
                        color:"#808080",
                        padding:-10,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        drawBorder: false,
                        color: "#D8D8D8",
                        borderDash: [5, 5],
                      },
                      ticks: {
                        callback: function (value: any) {
                          return `₹${value}k`;
                        },
                        fontSize: 14,
                        fontFamily:"SF Pro Text Regular",
                        color:"#808080",
                        padding:20,
                        min:0,
                        stepSize: 20,
                      },
                      beginAtZero: true,
                      offset: true,
                    },
                  ],
                
            }
        };
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>

                    {
                        this.state.currentPage == "Dashboard" &&
                        <>
                            <Grid container  className={classes.hidescroll}>
                                <Grid item xl={8} md={8} sm={12} xs={12}>
                                    <Box className={classes.ayahDashboardMainContainer} component="div">
                                        <Box className={classes.topTotalMainContainer} component="div">
                                            <Box data-test-id="showTotalBooking" className={classes.totalBookingContainer} component="div" onClick={()=>this.showTotalBooking()}>
                                                <Box className={classes.totalBookingContent} component="div">
                                                    <Typography variant="h6" className={classes.titleNumbersBooking} style={{ textAlign: "center" }}>
                                                     {this.state.ayahDashboardDetails.total_booking}
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.titleTotalBooking}>
                                                        Total Bookings
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box  data-test-id="showTotalMoney" className={classes.totalBookingContainer} component="div" onClick={()=>this.showTotalMoney()}>
                                                <Box className={classes.totalBookingContent} component="div">
                                                    <Typography variant="h6" className={classes.titleNumbersBooking} style={{ textAlign: "center" }}>
                                                        ₹{this.state.ayahDashboardDetails.total_money}
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.titleTotalBooking}>
                                                        Total Money
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className={classes.sosImageContainer} component="div">
                                                <img
                                                    data-test-id="ayahOval"
                                                    src={SOSImage}
                                                    className={classes.ayahProfile}
                                                    width={"85px"} />
                                            </Box>
                                        </Box>
                                        <Box className={classes.cardContainer}> 
                                        <Box className={classes.newBookingMainContainer} component="div">
                                            <Box className={classes.newBookingHeadingContainer} component="div">
                                                <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center" }}>
                                                    New Bookings
                                                </Typography>
                                                <Typography variant="h6" className={classes.titleSeeAll} style={this.seeAllData(this.state.ayahDashboardDetails?.new_bookings)}>
                                                    See All
                                                </Typography>
                                            </Box>
                                            {this.state.ayahDashboardDetails?.new_bookings?.length > 0 ? (
                                                this.state.ayahDashboardDetails.new_bookings.map((newBooking: any, index: number) => (
                                                <Box key={index} className={classes.allBookingContainer}>
                                                    <Box className={classes.bookingContainer}>
                                                    <Box className={classes.ayahInfoContainer}>
                                                        <Box className={classes.ayahImageContainer}>
                                                        <img
                                                            data-test-id="ayahOval"
                                                            src={this.getPatientProfileImage(newBooking.data.attributes.patient_profile_image)}
                                                            className={classes.ayahProfile}
                                                        />
                                                        </Box>
                                                        <Box className={classes.ayahInfoSection}>
                                                        {this.callCardDetails(classes, 1, newBooking.data.attributes)}
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.ayahActionButtonsContainer} component="div">
                                                        <Box className={classes.ayahtBtnContainer} component="div">
                                                            {this.handelBookingButton(newBooking,classes,index)}
                                                        </Box>
                                                    </Box>
                                                    </Box>
                                                </Box>
                                                ))
                                            ) : (
                                                this.noDataFound(classes)
                                            )}
                                        </Box>
                                        <Box className={classes.newBookingMainContainer}>
                                            <Box className={classes.newBookingHeadingContainer}>
                                                <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center" }} >Upcoming Bookings</Typography>
                                                <Typography variant="h6" className={classes.titleSeeAll} style={this.seeAllData(this.state.ayahDashboardDetails?.upcoming_bookings)} data-test-id="showUpcomingBooking" onClick={()=>this.showAllBooking("Upcoming Bookings")}>See All</Typography>
                                            </Box>
                                            {this.state.ayahDashboardDetails?.upcoming_bookings?.length > 0 ? (
                                                this.state.ayahDashboardDetails.upcoming_bookings.map((upcomingBooking: any, index: number) => (
                                                <Box  key={index} className={classes.allBookingContainer}>
                                                <Box className={classes.bookingContainer}>
                                                    <Box className={classes.ayahInfoContainer}>
                                                        <Box className={classes.ayahImageContainer}>
                                                            <img src={this.getPatientProfileImage(upcomingBooking.data.attributes.patient_profile_image)} className={classes.ayahProfile} />
                                                        </Box>
                                                        <Box className={classes.ayahInfoSection}>
                                                            <>{this.callCardDetails(classes,3,upcomingBooking.data.attributes)}</>
                                                            <Box className={classes.ayahShiftContainer}>
                                                                {this.ayahShift(classes,index)}
                                                            </Box>
                                                            <Box className={classes.ayahContactBtnContainer} component="div">
                                                                <Button data-test-id={`patientContactBtn${index}`} variant="contained" className={classes.contactBtn} style={{ textTransform: "capitalize", width: "100%" }} 
                                                                onClick={()=>this.showEmergencyContact()}>Patient’s Emergency Contact</Button>
                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                    <Box data-test-id="" className={classes.ayahActionButtonsContainer} >
                                                        <Box className={classes.ayahtBtnContainer} >   
                                                        <Button data-test-id="otpVerified" variant="contained" color="primary" className={classes.acceptedBtn} style={{ textTransform: "capitalize" }}>
                                                                <span> <img src={RightIcon} className={classes.icons} width={18} height={13} /></span>
                                                                <Typography variant="h6" className={classes.acceptedBtnText} style={{ textAlign: "center" }}>Accepted</Typography>
                                                        </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                               </Box>
                                               ))
                                            ) : (
                                                this.noDataFound(classes)
                                            )}
                                        </Box>
                                        <Box className={classes.newBookingMainContainer}>
                                            <Box className={classes.newBookingHeadingContainer}>
                                                <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center" }} data-test-id="showUpPastBooking">Past Bookings</Typography>
                                                <Typography variant="h6" className={classes.titleSeeAll} style={this.seeAllData(this.state.ayahDashboardDetails?.past_booking)} data-test-id="showPastBooking" onClick={()=>this.showAllBooking("Past Bookings")}>See All</Typography>
                                            </Box>
                                            {this.state.ayahDashboardDetails?.past_booking?.length > 0 ? (
                                                this.state.ayahDashboardDetails.past_booking.map((pastBooking: any, index: number) => (
                                                <Box key={index} className={classes.allBookingContainer}>
                                                    <Box className={classes.bookingContainer}>
                                                        <Box data-test-id="pastbooking" className={classes.ayahInfoContainer}>
                                                            <Box className={classes.ayahImageContainer}>
                                                                <img src={this.getPatientProfileImage(pastBooking.data.attributes.patient_profile_image)} className={classes.ayahProfile} />
                                                            </Box>
                                                            <Box data-test-id="pastbooking"  className={classes.ayahInfoSection}>
                                                                {this.callCardDetails(classes,4,pastBooking.data.attributes)}
                                                            </Box>
                                                        </Box>
                                                        <Box className={classes.ayahActionButtonsContainer} component="div">
                                                            <Box className={classes.ayahtBtnContainer} component="div">
                                                                <Button data-test-id="otpVerified" variant="contained" color="primary" className={classes.completedBtn} style={{ textTransform: "capitalize" }}>
                                                                    <span>
                                                                        <img
                                                                            data-test-id="ayahOval"
                                                                            src={GreenRightIcon}
                                                                            className={classes.icons}
                                                                            width={"18px"}
                                                                            height={"13px"} />
                                                                    </span>
                                                                    <Typography variant="h6" className={classes.completedBtnText} style={{ textAlign: "center" }}>
                                                                        Completed
                                                                    </Typography>
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                               ))
                                            ) : (
                                                this.noDataFound(classes)
                                            )}
                                        </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Dialog
                                open={this.state.openDialog}
                                className={classes.dialogBoxWeb}
                                PaperProps={{
                                  style: {
                                    borderRadius: "10px",
                                    maxWidth: "420px",
                                    width: "420px",
                                    height: "263px",
                                  },
                                }}
                                disableScrollLock 
                            >
                                <DialogContent className={classes.dialogBoxContentMainContainer}>
                                    <Box className={classes.dialogBoxContentContainer}>
                                        <Typography variant="h6" className={classes.dialogContentText} style={{ textAlign: "center" }}>
                                            Contact Patient’s
                                        </Typography>
                                        <Typography variant="h6" className={classes.dialogContentText} style={{ textAlign: "center" }}>
                                            Emergency phone Number
                                        </Typography>
                                        <Typography variant="h6" className={classes.dialogConfirmText} style={{ textAlign: "center" }}>
                                            Are you Sure?
                                        </Typography>
                                    </Box>
                                    <Box className={classes.capturedBtnContainer}>
                                        <Button data-test-id="patientDialogNoBtn" variant="contained" color="primary" className={classes.dialogNoBtn} style={{ textTransform: "capitalize" }} onClick={() => this.showEmergencyContact()}>No</Button>
                                        <Button data-test-id="patientDialogYesBtn" variant="contained" color="primary" className={classes.dialogYesBtn} style={{ textTransform: "capitalize" }}>Yes</Button>
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </> 
                    }
                    {
                        this.state.currentPage == "Total Money"
                        &&
                        <Grid className={classes.upcomingBookingContainer} container>
                            <Grid item xl={8} md={8} sm={12} xs={12}>
                                <Box className={classes.earningDashboardMainContainer} component="div">
                                    <>{this.headerBackButtonProvider(classes, "Total Money")}</>
                                    <Box className={classes.totalEarningsContainer} component="div">
                                        <Box className={classes.earningSection} component="div">
                                            <Box className={classes.earningSubSection} component="div">
                                                <Box className={classes.earningTypeImageContainer} component="div">
                                                    <img
                                                        data-test-id="earingIconImage"
                                                        src={HomeVisitIcon}
                                                        className={classes.earningTypeImage}
                                                        width={"72px"}
                                                    />
                                                </Box>
                                                <Box className={classes.earningTypeSection} component="div">
                                                    <Typography variant="h6" className={classes.earnings}>
                                                        ₹40,400
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.type}>
                                                        Home Visit
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </Box>
                                        <Box className={classes.earningSection} component="div">
                                            <Box className={classes.earningSubSection} component="div">
                                                <Box className={classes.earningTypeImageContainer} component="div">
                                                    <img
                                                        data-test-id="earingIconImage"
                                                        src={TeleconsultationIcon}
                                                        className={classes.earningTypeImage}
                                                        width={"70px"}
                                                    />
                                                </Box>
                                                <Box className={classes.earningTypeSection} component="div">
                                                    <Typography variant="h6" className={classes.earnings}>
                                                        ₹20,100
                                                    </Typography>
                                                    <Typography variant="h6" className={classes.type}>
                                                        Teleconsultation
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className={classes.monthEarningMainContainer} component="div">
                                        <Typography variant="h6" className={classes.monthEarningTitle}>
                                            Monthly Earnings
                                        </Typography>
                                        <Box className={classes.graphContainer} component="div">
                                            <Bar
                                                data={this.state.graphData}
                                                width={undefined}
                                                height={undefined}
                                                options={options}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className={classes.recentTransactionsMainContainer} component="div">
                                        <Box className={classes.newBookingHeadingContainer} component="div">
                                            <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center" }} data-test-id="updateBooking">Recent Transactions</Typography>
                                            <Typography
                                            variant="h6"
                                            className={classes.titleSeeAll}
                                            style={this.seeAllData(this.state.totalBookingDetails)}
                                            data-test-id="seeAllTransaction"
                                            onClick={() => {
                                                this.showRecentTransaction()
                                            }}
                                            >
                                            See All
                                            </Typography>
                                        </Box>
                                        <Box className={classes.allTransactionContainer} component="div">
                                            {this.state.totalBookingDetails?.length > 0 ? (
                                                this.state.totalBookingDetails.slice(0, 10).map((totalBooking: any, index: number) => (
                                                    <Box key={index}>
                                                        <Box data-test-id={`transaction${index}`} className={classes.transactionContainer}>
                                                            {this.recentTransaction(classes,1,totalBooking)}
                                                        </Box>
                                                        {this.showHorizontalLine(classes,index)}
                                                    </Box>
                                                ))
                                            ) : (
                                                this.noDataFound(classes)
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>                  
                        }
                    {
                        this.state.currentPage == "Recent Transactions"
                        &&
                        <Grid container className={classes.recentTransaction}>
                            <Grid item xl={8} md={8} sm={12} xs={12}>
                                <Box className={classes.earningDashboardMainContainer} component="div">
                                    <>{this.headerBackButtonProvider(classes, "Recent Transactions")}</>
                                    <>{this.transacationFiltersProvider(classes,)}</>

                                    <Box className={classes.timeFilterMainContainer} component="div">
                                        <Box className={classes.timeFilterContainer} component="div">
                                           {this.recentTransactionSelection(classes)}
                                        </Box>
                                        <Typography variant="h6" className={classes.filterBottomText}>
                                            Last update few minutes ago
                                        </Typography>
                                    </Box>
                                    {this.state.totalBookingDetails?.length > 0 && (() => {
                                        const groupedBookings: { [key: string]: any[] } = {};
                                        this.state.totalBookingDetails.forEach((totalBooking: any) => {
                                            const createdDate = this.formatBookingDate(totalBooking.attributes.created_at);

                                            if (!groupedBookings[createdDate]) {
                                                groupedBookings[createdDate] = [];
                                            }
                                            groupedBookings[createdDate].push(totalBooking);
                                        })
                                        return Object.keys(groupedBookings).map((date, index) => (
                                            <Box key={index} className={classes.dayTransactionsListContainer} component="div">
                                                <Typography variant="h6" className={classes.dayTransactionsListTitle} data-test-id="updateBooking">
                                                    {date}
                                                </Typography>
                                                <Box className={classes.allTransactionContainer} component="div">
                                                    {groupedBookings[date].map((totalBooking, subIndex) => (
                                                        <Box key={subIndex}>
                                                            <Box data-test-id={`allTransaction${subIndex}`} className={classes.transactionContainer}>
                                                                {this.recentTransaction(classes, 2, totalBooking)}
                                                            </Box>
                                                            <Box className={classes.horizontalLine}></Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        ));
                                    })()}
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    {
                        this.state.currentPage == "Total Bookings"
                        &&
                        <Grid container className={classes.reservation}>
                            <Grid item xl={8} md={8} sm={12} xs={12}>
                                <Box className={classes.earningDashboardMainContainer}>
                                    {this.headerBackButtonProvider(classes, "Total Bookings")}
                                    {this.transacationFiltersProvider(classes)}
                                    <Box className={classes.totalBookingListContainer}>
                                        {this.state.totalBookingDetails?.length > 0 ? (
                                            this.state.totalBookingDetails.map((totalBooking: any, index: number) => (
                                            this.showCard(classes,totalBooking,index)
                                           ))
                                        ) : (
                                            this.noDataFound(classes)
                                        )}
                                    </Box>

                                </Box>
                            </Grid>
                        </Grid>
                    }
                   {
                        this.bookingTypeStatus() && (
                            <>
                                <Grid container className={classes.upcomingBookingContainer}>
                                    <Grid item xl={8} md={8} sm={12} xs={12}>
                                        <Box className={classes.earningDashboardMainContainer} component="div">
                                            {this.headerBackButtonProvider(classes, this.state.currentPage)}
                                            {this.renderBookingList(classes)}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                     <Dialog open={this.state.isHealthDetailsDialogOpen} PaperProps={{ style: { borderRadius: "15px", maxWidth:"480px", width:"480px",height:"656px", overflowY: "auto" } }}>
                                <Box className={classes.dialogWrapper}>

                                    <Box className={classes.healthDetailsDialogTitleConatiner}>
                                        <Typography className={classes.dialogTitle}>
                                            Health Details
                                        </Typography>
                                        <CloseIcon data-test-id="closeHealthDetailsAyahDialog" onClick={this.handleCloseHealthDetailsDialog} />
                                    </Box>
                                    {this.healthDetailsContainer(classes)}
                                </Box>
                     </Dialog>                
                    
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(DashboardView);
// Customizable Area End